.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.error {
    color: #FF5630;
    transition: 100ms linear;
}