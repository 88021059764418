.main-action, .landing-main-action {
    outline: none;
    background-color: #0052CC;
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    padding: 4px;
    border-radius: 20px;
    border: 2px solid #0052CC;
    margin-top: 4px;
    cursor: pointer;
    margin-top: 16px;
    max-width: 400px;
    width: 100%;
    text-transform: uppercase;
}

.main-action {
    background-color: #57C2F9;
    border: 2px solid #57C2F9;
    color: white;
    font-weight: 600;
}

.main-action:hover {
    background-color: #49bdf7;
    border: 2px solid #49bdf7;
    transition: 0.2s linear;
    box-shadow: 1px 1px 6px 2px lightgrey
}

.main-action .disabled, .landing-main-action .disabled {
    background-color: #68cdff;
    border: 2px solid #68cdff;
    transition: 100ms linear;
}

.landing-main-action:hover {
    background-color: #9dddfd;
    border: 2px solid #9dddfd;
    transition: 0.2s linear;
    box-shadow: 1px 1px 6px lightgrey
}

@media only screen and (max-width: 720px) {
    .main-action {
        background-color: #57C2F9;
        border: 2px solid #57C2F9;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
    }
    .main-action:hover {
        background-color: #57C2F9;
        border: 2px solid #57C2F9;
        color: white
    }
}