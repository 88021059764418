.client {
    line-height: 28px; 
    width: 100%;
    max-width: 450px;
    min-width: 280px;
    padding: 4px 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color: none;
}

.client:hover{
    transition: 250ms ease-in-out;
    background-color: #EEECEC
}

.clientCard:hover {
    background-color: #eae8ff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.15s
}