.label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    color: N200;
    line-height: 16px;
    margin-top: 16px;
    color: #272A2D;
    max-width: 400px;
    width: 100%
}

.username, .password, .file, .select, .textarea {
    outline: none;
    background-color: #FAFBFC;
    font-size: 16px;
    line-height: 28px;
    padding: 4px;
    border-radius: 20px;
    border: 2px solid #DFE1E6;
    margin-top: 4px;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
}

.select {
    min-width: 292px;
    max-width: 400px;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 10px;
}

.textarea {
    height: 150px
}

.username:focus, .password:focus, .select:focus {
    border: 2px solid #4C9AFF;
    transition: 100ms linear
}

input, label {
    display: block;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}