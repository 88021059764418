body {
  margin: 0;
  font-family: 'ProximaNova';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  letter-spacing: 0.1px
}

@font-face {
  font-family: "ProximaNova";
  src: url("./assets/font/SofiaProBlack.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  color: #272A2D;
}

@font-face {
  font-family: "ProximaNova";
  src: url("./assets/font/SofiaProLight.ttf") format("truetype");
  font-weight: 300;
  font-style: light;
  color: #272A2D;
}

@font-face {
  font-family: "ProximaNova";
  src: url("./assets/font/SofiaProSemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: bold;
  color: #272A2D;
}

@font-face {
  font-family: "ProximaNova";
  src: url("./assets/font/SofiaProBold.ttf") format("truetype");
  font-weight: 600;
  font-style: bold;
  color: #272A2D;
}

p {
  font-weight: 500
}

a {
  font-weight: 400
}